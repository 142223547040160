.input {
    border-color: #5b5289;
}
.input:focus {
    border-color: #b4afcc;
}
.signin:hover {
    background-color: white;
    color: #e66025;
    border-color: white;
}
.text-primary-hover {
    color: #eb8153;
}
.text-primary-hover:hover {
    color: white;
}
.primary-text-color {
    color: #eb8153;
}

.brand-title-black {
    color: white;
    padding-left: 5%;
}
.brand-title-white {
    color: #323B47;
    padding-left: 5%;
}

.card-tabs.style-1 .nav-tabs .nav-link.active {
    background-color: #587BF8 !important;
}
